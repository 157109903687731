<template>
  <div class="overflow-auto card" v-if="years.length > 0">
    <div v-if="loading">
      <div class="text-center">
        <select
          class="form-control form-control-sm shadow-none col-md-2"
          v-model="select_year"
          @change="change_year"
        >
          <option v-for="(item, idx) in years" :key="idx" :value="item">{{
            item
          }}</option>
        </select>
      </div>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js";
import { HTTPGET } from "../api";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartId: {
      default: "bar-chart"
    },
    datasetIdKey: {
      default: "label"
    },
    width: {
      default: 400
    },
    height: {
      default: 300
    },
    cssClasses: {
      default: "barchart"
    },
    styles: {
      default: () => {}
    },
    plugins: {
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    title_summ: 100,
    years: [],
    select_year: new Date().getFullYear(),
    chartData: {
      labels: [
        "янв",
        "фев",
        "мар",
        "апр",
        "май",
        "июн",
        "июл",
        "авг",
        "сен",
        "окт",
        "ноя",
        "дек"
      ],
      datasets: [
        {
          label: `количество ремонтов`,
          data: null
        }
      ]
    },
    chartOptions: {
      responsive: false
    }
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/repairs/count_repairs_for_graphics.php",
        params: {
          year: this.select_year
        }
      })
        .then(response => {
          this.chartData.datasets[0].data = response.data.count;
          this.chartData.datasets[0].label = `количество ремонтов ${response.data.summ}`;
          this.years = response.data.years;
          if (!this.years.includes(`${new Date().getFullYear()}`)) {
            this.years.push(`${new Date().getFullYear()}`);
          }
        })
        .then(() => (this.loading = true));
    },
    change_year() {
      this.get_data();
    }
  }
};
</script>
<style scoped>
.barchart {
  display: table;
  margin: auto;
}
select {
  margin: 3px auto;
  font-size: 0.9em;
}
</style>
