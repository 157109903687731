<template>
  <div class="modal fade" tabindex="-1" id="exampleModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title text-center" id="exampleModalLabel">
            У Вас есть ремонты по которым необходимо сформировать гарантийный
            отчет
          </h5>
        </div>
        <div class="modal-body" v-if="list">
          <table class="table table-sm">
            <tr>
              <th></th>
              <th>№ квит.</th>
              <th>№</th>
              <th>дата выдачи</th>
            </tr>
            <tr v-for="(item, idx) in list" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.receiptNumber }}</td>
              <td>{{ item.repair_number }}</td>
              <td>{{ item.repair_is_over_data }}</td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
    <button
      class="q"
      id="show_modal"
      data-toggle="modal"
      data-target="#exampleModal"
    ></button>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "CheckReport",
  data: () => ({
    list: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    open() {
      document.getElementById("show_modal").click();
    },
    get_data() {
      HTTPGET({
        url: "/home/check_report.php"
      }).then(response => {
        if (response.data) {
          this.list = response.data;
          this.open();
        }
      });
    }
  }
};
</script>
<style scoped>
.q {
  display: none;
}
table {
  font-size: 0.7em;
}
td,
th {
  text-align: center;
}
</style>
