<template>
  <div>
    <div><UserInfo /></div>
    <div v-if="CHANGE_USER_MENU_VISIBLE"><ChangeUser /></div>
    <div v-else />
    <button
      v-if="last_upload_file_1"
      class="btn btn-sm btn-outline-primary shadow-none mr-1 mb-1"
      @click="download_blank"
    >
      скачать бланк заказа запчастей от
      {{ last_upload_file_1.substr(0, last_upload_file_1.length - 4) }}
    </button>
    <div v-if="USER_INFO.status !== 'admin'"><TK /></div>
    <button
      v-else
      class="btn btn-sm btn-outline-primary shadow-none mr-1 mb-1"
      @click="go_otpravki"
    >
      отправки
    </button>
    <div v-if="USER_INFO.status === 'admin'"><UsersOnline /></div>
    <div v-else></div>
  </div>
</template>
<script>
import UserInfo from "./UserInfo";
import ChangeUser from "./ChangeUser";
import { mapGetters } from "vuex";
import UsersOnline from "./UsersOnline.vue";
import TK from "./TK.vue";
import { HTTPGETNOLOADER } from "@/api";
export default {
  name: "HomeContent",
  components: {
    UserInfo,
    ChangeUser,
    UsersOnline,
    TK
  },
  data: () => ({
    last_upload_file_1: null
  }),
  computed: {
    ...mapGetters(["CHANGE_USER_MENU_VISIBLE", "USER_INFO"])
  },
  mounted() {
    this.get_last_upload_files();
  },
  methods: {
    go_otpravki() {
      this.$router.push("TK");
    },
    download_blank() {
      if (this.last_upload_file_1) {
        window.location.href = `https://sc-kalibr.ru/api/admin/blank/download_blank/${this.last_upload_file_1}`;
      }
    },
    get_last_upload_files() {
      HTTPGETNOLOADER({
        url: "/admin/blank/upload_blank.php",
        params: {
          last_upload_file: 1
        }
      }).then(response => {
        this.last_upload_file_1 = response.data.file_1;
      });
    }
  }
};
</script>
