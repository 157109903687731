<template>
  <div>
    <BarChart v-if="USER_INFO.change_user" />
    <div v-for="n of news" :key="n.id" class="card">
      <div class="card-body">
        <h5 class="card-title">{{ n.header_news }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ n.date_news }}</h6>
        <p class="card-text" v-html="n.text_news"></p>
        <div v-if="USER_INFO.status === 'admin' && n.likes">
          <span class="badge badge-success m-1 p-2">{{ n.e_like }}</span>
          <span class="badge badge-danger m-1 p-2">{{ n.not_like }}</span>
        </div>
        <div v-if="n.likes && !n.like_status">
          <button
            class="btn btn-sm btn-outline-success shadow-none m-1"
            @click="set_like(1, n.id)"
          >
            хорошая новость
          </button>
          <button
            class="btn btn-sm btn-outline-danger shadow-none m-1"
            @click="set_like(0, n.id)"
          >
            лучше по старому
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import BarChart from "@/components/BarChart.vue";
import { mapGetters } from "vuex";
export default {
  name: "News",
  components: {
    BarChart
  },
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    news: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET("home/news/get_news.php").then(
        response => (this.news = response.data)
      );
    },
    set_like(i, id) {
      HTTPGET({
        url: "home/news/set_like_news.php",
        params: {
          like: i,
          news_id: id
        }
      }).then(() => this.get_data());
    }
  }
};
</script>
<style scoped>
.card {
  margin-bottom: 5px;
  font-size: 0.8em;
}
</style>
