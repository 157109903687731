<template>
  <div class="wrapper" :class="USER_INFO.status === 'admin' && 'p-2'">
    <table class="table table-sm table-hover table-bordered" v-if="orders">
      <tbody v-if="USER_INFO.status !== 'admin'">
        <tr>
          <th colspan="10" class="text-center">
            грузы отпрвленные в Ваш адрес ТК
            <span v-if="orders.dl">Деловые линии </span>
            <span v-if="orders.gtd">Кашалот </span>
            <span v-if="orders.pec">ПЭК </span>
            <span v-if="orders.cdek">СДЭК </span>
          </th>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>получатель</th>
          <th>номер накладной ТК</th>
          <th>статус</th>
          <th>доставка до</th>
          <th>стоимость</th>
          <th>оплата</th>
          <th>дата отправки</th>
          <th>дата прибытия груза</th>
          <th>дата начала платного хранения</th>
          <th>адрес получателя</th>
        </tr>
      </tbody>
      <tbody v-if="orders.cdek">
        <tr>
          <th colspan="10" class="text-center">СДЭК</th>
        </tr>
        <tr v-for="(item, idx) in orders.cdek" :key="idx + 'cd'">
          <td>{{ item.recipient }}</td>
          <td>
            <a
              :href="`https://www.cdek.ru/ru/tracking?order_id=${item.cdek_id}`"
              target="_blanc"
              >{{ item.cdek_id }}</a
            >
          </td>
          <td>
            <div>{{ item.status }}</div>
            <div>{{ item.status_sity }}</div>
            <div>{{ item.status_date }}</div>
          </td>
          <td></td>
          <td class="text-center">{{ item.total_sum }}</td>
          <td></td>
          <td>{{ item.date_created }}</td>
          <td>
            <div v-if="item.date_prib">прибыл</div>
            <div>{{ item.date_prib }}</div>
          </td>
          <td></td>
          <td>{{ item.to_location }}</td>
        </tr>
      </tbody>
      <tbody v-if="orders.dl">
        <tr>
          <th colspan="10" class="text-center">деловые линии</th>
        </tr>
        <tr v-for="(item, idx) in orders.dl" :key="idx + 'dl'">
          <td>{{ item.poluchatel }}</td>
          <td>
            <a
              :href="
                `http://www.dellin.ru/tracker/?mode=search&rwID=${item.id_zakaza}`
              "
              target="_blanc"
              >{{ item.id_zakaza }}</a
            >
          </td>
          <td>{{ item.status }}</td>
          <td>{{ item.terminal_or_dver }}</td>
          <td class="text-center">
            <div>{{ item.summa }}</div>
            <div class="pay">{{ `&#8381; ${item.payer}` }}</div>
          </td>
          <td>{{ item.oplata }}</td>
          <td>{{ item.data_otpravki }}</td>
          <td>{{ item.date_prib === "00.00.0000" ? "" : item.date_prib }}</td>
          <td>{{ item.nachalo_plat_hran }}</td>
          <td>{{ item.adress }}</td>
        </tr>
      </tbody>
      <tbody v-if="orders.gtd">
        <tr>
          <th colspan="10" class="text-center">Кашалот</th>
        </tr>
        <tr v-for="(item, idx) in orders.gtd" :key="idx + 'dl'">
          <td>{{ item.poluchatel }}</td>
          <td>
            <a
              :href="`https://tk-kit.com/?tracking=${item.nomer_nakl}`"
              target="_blanc"
              >{{ item.nomer_nakl }}</a
            >
          </td>
          <td>{{ item.status }}</td>
          <td></td>
          <td class="text-center">{{ item.price }}</td>
          <td></td>
          <td>{{ item.data_otpravki }}</td>
          <td>
            <div>{{ item.date_prib_status }}</div>
            <div>{{ item.date_prib }}</div>
          </td>
          <td></td>
          <td>{{ item.gorod_poluchatel }}</td>
        </tr>
      </tbody>
      <tbody v-if="orders.pec">
        <tr>
          <th colspan="10" class="text-center">ПЭК</th>
        </tr>
        <tr v-for="(item, idx) in orders.pec" :key="idx + 'dl'">
          <td>{{ item.poluchatel }}</td>
          <td>
            <a
              :href="
                `https://pecom.ru/services-are/order-status/?code=${item.nakl}`
              "
              target="_blanc"
              >{{ item.nakl }}</a
            >
          </td>
          <td>{{ item.status }}</td>
          <td></td>
          <td class="text-center">
            <div>{{ item.stoimost }}</div>
            <div class="pay">
              {{ item.payer === "" ? null : `&#8381; ${item.payer}` }}
            </div>
          </td>
          <td>{{ item.oplata }}</td>
          <td>{{ item.date_otpravki }}</td>
          <td>
            <div>
              {{
                item.data_pribitiya_fakt ? "прибыл" : "плановая дата прибытия"
              }}
            </div>
            <div>{{ item.date_prib }}</div>
          </td>
          <td>{{ item.plat_hran }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { HTTPGET } from "@/api";
export default {
  name: "TK",
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    orders: null
  }),
  mounted() {
    this.get_orders_tk();
  },
  methods: {
    get_orders_tk() {
      HTTPGET({
        url: "template/tk/get_list_orders_tk.php"
      }).then(response => (this.orders = response.data));
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
}
th,
td {
  vertical-align: middle;
}
.pay {
  font-size: 0.9em;
  white-space: nowrap;
}
.wrapper {
  overflow: auto;
}
</style>
