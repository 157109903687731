<template>
  <div>
    <div v-if="list_users">
      <table class="table table-sm">
        <tr v-for="(item, idx) in list_users" :key="idx">
          <td>{{ idx + 1 }}</td>
          <td>{{ item.user }}</td>
          <td>{{ item.url }}</td>
          <td>{{ item.date_time }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGETNOLOADER } from "@/api";
export default {
  name: "UsersOnline",
  data: () => ({
    list_users: null,
    interval: null
  }),
  mounted() {
    this.get_data();
    this.get_data_interval();
  },
  methods: {
    get_data_interval() {
      this.interval = setInterval(() => {
        this.get_data();
      }, 5000);
    },
    get_data() {
      HTTPGETNOLOADER({
        url: "/template/useractionhistory/users_online.php"
      }).then(response => (this.list_users = response.data));
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
  margin-top: 10px;
}
</style>
